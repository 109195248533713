//Render the List of Workers registred in each Meeting Point


import React, { useContext, useState, useEffect } from "react";
import "../styles/List.css";
import Context from "../store/context";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";


function List(props) {
  //Material UI - Styles 
  const useStyles = makeStyles({
    root: {
      width: "100%",
      borderRadius: 5,
    },
    container: {
      /* maxHeight: 250,
      maxWidth: 400, */
    },
  });

  const useStylesButton = makeStyles({
    root: {
      color: "white",
      backgroundColor: "#0593da",
      borderRadius: "15px",
    },
    label: {},
  });

  const classes = useStyles();

  const classesButton = useStylesButton();

  //Hook to store Data from the Fetch
  const [worker, setWorker] = useState([]);

  //Method to Fetch data. Data is a list of workers for each Meeting Point
  const fectchData = () => {
    axios
      .post(
        "https://meetingpoint.2bconnect.pt/teste/php/middleware/readWorkerArea.php",
        {
          mp: state.listEvent,
        }
      )
      .then((res) => {
        setWorker(res.data.status.map((el) => el));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //UseEffect for Fetch Data when page is loaded
  useEffect(() => {
    /* setInterval(() =>  */fectchData()/* , 10000) */;
  }, []);

  //Variable State is necessary to use Global State Variables in this componnet 
  const { state } = useContext(Context);

  const areaList =
    state.listEvent === 0 ? "Fora da Zona Segura" : state.listEvent;

  return (
    <div className="list">
      <div className="list2">
        {/*Display List of Workers in Meeting Point selected by user */}
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <div>
                    <div className='header-list'>
                        <h4> {areaList === 5 ? "Brigadistas" : "MP_" + areaList}</h4>
                    </div>
                  
                    <TableCell className="column-name">
                      Nome
                    </TableCell>
                    <TableCell className="column-name">
                      Empresa
                    </TableCell>
                    <TableCell className="column-name">
                      Tipo
                    </TableCell>
                  </div>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Map the Data from DataBase. Stored in Worker Hook */}
                <p>{worker.length == 0 ?  "Nenhum trabalhador encontrado" : null} </p>
                {worker
                  .sort((a, b) => a.worker_name.localeCompare(b.worker_name))
                  .map((filteredWorker) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={filteredWorker.input}
                      >
                        <div>
                          <TableCell className="column-name">
                            {filteredWorker.worker_name}
                          </TableCell>
                          <TableCell className="column-name">
                            {filteredWorker.worker_company}
                          </TableCell>
                          <TableCell className="column-name">
                            {filteredWorker.type}
                          </TableCell>
                        </div>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/*Button to stop showing this component */}
        <Button
          className={classesButton.root}
          variant="contained"
          onClick={() => {
            props.close();
          }}
        >
          Fechar
        </Button>
      </div>
    </div>
  );
}

export default List;
