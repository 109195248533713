import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";

const useStyles = makeStyles(() => ({
    buttons: {
      color: 'white',
      //maxWidth: '70px',
      marginRight: '1%',
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'firebrick'
     }
    }
  }));

function RemoveUserDialog(props) {
    
    const classes = useStyles();

    const [username, setUsername] = useState("");


    function removeAlert() {
        axios.post("https://meetingpoint.2bconnect.pt/teste/php/middleware/delete-alert.php", {
            name: props.name,
    })
      .then(function (response) {
        props.fetchAlertsData();
      })
      .catch(function (error) {
        console.log(error);
      })

    }


    return (        
          <Button size="small" variant="contained" className={classes.buttons} onClick={() => { window.confirm('Tem a certeza que pretende remover o Alerta?') && removeAlert() } }>
            Remover
          </Button>               
      );

}

export default RemoveUserDialog;