//Component responsable to show to user the complet list of workers
//SearchList is also responsable to do the search by worker's name or company.

import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../store/context";
import "../styles/SearchList.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InfoCard from "./InfoCard";

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Reset from "./Reset";

function SearchList({ search, updateData }) {
  //Matrial UI styling
  //Atention! This component also uses a CSS.
  const useStylesInput = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(0),
    },
  }));

  const classes = useStylesInput();

  const { state } = useContext(Context);

  const [showPopUp, setShowPopUp] = useState(false);

  //Hook is used to store the value that  user inserts on search box.
  const [input, setInput] = useState("");



  //Method to store the value on input variable.
  const handleChange = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  //Method to render the Data Info of each worker
  const handleClick = () => {
    setShowPopUp(!showPopUp);
  };

  //Method to the search for worker's name or company.
  //Right now the program only allows search by worker's name
  function searchEngine() {
    if (input.length > 0) {
      search = search.filter((i) => {
        return i.worker_name.match(input) ||
         i.worker_name.toUpperCase().match(input) || 
         i.worker_name.match(input)
      });
    }
  }

  return (
    <div className="searchList">
      {/* Show List if showPopUp's value is true*/}
      {showPopUp ? <InfoCard close={handleClick} /> : null}

      <div class="table" onChange={searchEngine()}>
        <div class="row header">
          <div class="cell index-name">Nome</div>
          <div class="cell index-empresa">Empresa</div>
          <div class="cell index-tipo">Tipo</div>
          <div class="cell index-mp">MP</div>
        </div>
        <scroll-container>
          <div class="row">
            <div class="cell name" data-title="Name">
              {/* Order the list of Workers first alphabetic and next shows first workers not-registered of Meeting Point and next the workers registred in Meeting Pont*/}
              {search
                .sort(
                  (a, b) =>
                    a.worker_mp - b.worker_mp ||
                    a.worker_name.localeCompare(b.worker_name)
                )
                .map((worker) => {
                  return (
                    <p style = {{height:"40px"}}
                      className={
                        worker.worker_mp == 1 ? "text-not-safe" : "text-safe"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        state.id = worker.input;
                        if (worker.worker_mp == 1) {
                          handleClick();
                        }
                      }}
                    >
                      {/* If worker isn't registered the color will be red and the user can click it*/}
                      {/* If worker is registered the color will be green and the user can't click it*/}{" "}
                      {worker.worker_mp == 1 ? (
                        <Link style={{ color: "inherit" }}>
                          {worker.worker_name}
                        </Link>
                      ) : (
                        <p style = {{height:"40px"}}>{worker.worker_name}</p>
                      )}
                    </p>
                  );
                })}
            </div>

            <div class="cell empresa" data-title="company">
              {search.map((worker) => {
                return (
                  <p style = {{height:"40px"}}
                    className={
                      worker.worker_mp == 1 ? "text-not-safe" : "text-safe"
                    }
                  >
                    {worker.worker_company}
                  </p>
                );
              })}
            </div>
            <div class="cell tipo" data-title="type">
              {search.map((worker) => {
                return (
                  <p style = {{height:"40px"}}
                    className={
                      worker.worker_mp == 1 ? "text-not-safe" : "text-safe"
                    }
                  >
                    {worker.type}
                  </p>
                );
              })}
            </div>
            <div class="cell mp" data-title="mp">
              {search.map((worker) => {
                return (
                  <p style = {{height:"40px"}}
                    className={
                      worker.worker_mp == 1 ? "text-not-safe" : "text-safe"
                    }
                   >
                  {worker.mp == 0 ? "---" : worker.mp}   
                  </p> 
                )
              })}
              
            </div>
          </div>
        </scroll-container>
      </div>

      {/*Render the textfield for your user insert data to the search*/}
      <TextField
        className={classes.margin}
        placeholder="Nome"
        onChange={handleChange}
        value={input}
        id="input-with-icon-textfield"
        label="Pesquisa"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className="reset-button">
        <Reset resetData={updateData} />
      </div>
    </div>
  );
}

export default SearchList;
