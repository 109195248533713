import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../styles/Gvir.css';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import { red, blue, green } from "@material-ui/core/colors";

const Gvir = () => {
	const useStyles = makeStyles({
		root: {
			width: '70%',
			maxWidth: '30%',
			marginBottom: '15px',
			'@media (max-width: 1134px)': {
				maxWidth: '80%',
			},
			'@media (max-width: 514px)': {
				maxWidth: '99%',
			},
		},
		table: {
			minWidth: 200,
		},
		rows: {
			color: 'black',
		},
		head: {
			color: 'white',
			backgroundColor: 'black',
		},
		dataGrid: {
			width: '50%',
			marginBottom: '4rem',
		},
		submitButton: {
			width: '100%',
			marginTop: '1rem',
			color: '#094b9b',
			border: '1px solid #094b9b',
			'&:focus': {},
		},
	});

	const [gvirData, setGvirData] = useState([]);

	const [date, setDate] = useState('');

	const [rows, setRows] = useState([]);

	const [columns, setColumns] = useState([]);

	const [loading, setLoading] = useState(true);

	const [thresholdH1, setThresholdH1] = useState('');

	const [thresholdH2, setThresholdH2] = useState('');

	const [waitingTime, setWaitingTime] = useState();

	const [waitingTimeRead, setWaitingTimeRead] = useState();

	useEffect(() => {
		setLoading(true);
		fetchGvirOperation()/* .then((res) => {
			//setWaitingTime(gvirData.waitingtime);
			setWaitingTime(res.data.result[0].waitingtime);
			setLoading(false);
		}); */
		fetchGvirStatus().then(() => {
			setThresholdH1(gvirData.thresholdH1);
			setThresholdH2(gvirData.thresholdH2);
			setLoading(false);
		});

		console.log('fetchGvirStatus()');
	}, []);

	const renderSummaryDownloadButton = (params) => {
		return (
			<strong>
				<Button
					variant="contained"
					color="primary"
					size="small"
					style={{ marginLeft: 16 }}
					onClick={() => {
						//parseName(params.row.col6)
					}}
				>
					More Info
				</Button>
			</strong>
		)
	}

	let reading = "Reading"
	let writing = "Writing"
	let noReading = "No reading"

	function getChipProps(params: GridRenderCellParams): ChipProps {
		if (params.value === "No reading") {
		  return {
			icon: <WarningIcon style={{ fill: red[500] }} />,
			label: params.value,
			style: {
			  borderColor: red[500]
			}
		  };
		} else if (params.value === "Reading"){
		  return {
			icon: <CheckCircleIcon style={{ fill: green[500] }} />,
			label: params.value,
			style: {
			  borderColor: blue[500]
			}
		  };
		} else  if (params.value === "Writing") {
			return {
			  icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
			  label: params.value,
			  style: {
				borderColor: blue[500]
			  }
			};
		  }
	  }

	useEffect(() => {
		if (gvirData) {
			setRows([
				{ id: 1, property: 'LoRa ID', value: gvirData.id, editable: reading},
				{
					id: 2,
					property: 'Serial Number',
					value: gvirData.serialNumber,
					editable: reading
				},
				{ id: 3, property: 'Last Contact', value: date.replace('.000000', ''), editable: reading },
				{ id: 4, property: 'Status', value: gvirData.status, editable: reading },
				{
					id: 5,
					property: 'Gas Concentration',
					value: gvirData.gasConcentration,
					editable: reading
				},
				{
					id: 6,
					property: 'Sensor Temperature',
					value: gvirData.temperature,
					editable: reading
				},
				{
					id: 7,
					property: 'Response Factor',
					value: gvirData.responseFactor,
					editable: reading
				},
				{
					id: 8,
					property: 'Sensor Range',
					value: gvirData.sensorRange,
					editable: reading
				},
				{ id: 9, property: 'Cal 100', value: gvirData.cal100, editable: reading },
				{ id: 10, property: 'Cal 3000', value: gvirData.cal3000, editable: reading },
				{
					id: 13,
					property: 'Waiting Time (minutos)',
					value: waitingTimeRead,
					editable: writing


				},
				{
					id: 11,
					property: 'Threshold H1 (ppm)',
					value: gvirData.thresholdH1,
					editable: writing

				},
				{
					id: 12,
					property: 'Threshold H2 (ppm)',
					value: gvirData.thresholdH2,
					editable: writing



				},
				{
					id: 0,
					property: 'Threshold H3 (ppm)',
					value: "Definido no detector",
					editable: noReading



				}
			]);

			setColumns([
				{ field: 'property', headerName: 'Properties', flex: 1 },
				{
					field: 'value',
					headerName: 'GVIR Sensor values',
					flex: 1,
					editable: true,
				},
				{ field: 'editable', headerName: 'Editable', flex: 1 ,  renderCell: (params) => {
					return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
				  }},
				/* {
					field: 'action',
					headerName: 'Action',
					width: 180,
					renderCell: () => (
						
							<button>
								Save
							</button>
							
					),
				}, */
			]);
		}
	}, [gvirData, date, waitingTimeRead]);


	//Fetch GvirStatus for the first time
	const fetchGvirStatus = async () => {
		await axios
			.get(
				'https://meetingpoint.2bconnect.pt/api/gvir_status/read_last.php'
			)
			.then((res) => {
				setGvirData(res.data.sensorStatus);
				//setThresholdH1(res.data.sensorStatus.thresholdH1);
				//setThresholdH2(res.data.sensorStatus.thresholdH2);
				setDate(res.data.sensorStatus.dateTime.date);
			})
			.catch((err) => {
				console.log(err);
			});
		/* setThresholdH1(thresholdH1);
		setThresholdH2(false); */
		//setWaitingTime(false);
	};

	//Fetch GvirOperation for the first time
	const fetchGvirOperation = async () => {
		await axios
			.get(
				'https://meetingpoint.2bconnect.pt/api/gvir_operation/read_last.php'
			)
			.then((res) => {
				setWaitingTimeRead(res.data.result[0].waitingtime);
			})
			.catch((err) => {
				console.log(err);
			});
		//setWaitingTime(false);
	};

	const classes = useStyles();

	const handleRowEdition = async ({ id, field, value }) => {
		if (id === 11) setThresholdH1(value);
		console.log(thresholdH1)
		if (id === 12) setThresholdH2(value);

		if (id === 13) setWaitingTime(value);
	};

	const handleSubmission = async () => {


		let h1 = Number(thresholdH1)
		let h2 = Number(thresholdH2)
		let result
		let final = thresholdH2
		if (h2 <= h1) {
			result = h1 + 1
			final = result.toString()
		}
		console.log('h1: ' + thresholdH1)
		console.log('h2: ' + thresholdH2)
		console.log('wt: ' + waitingTime)
		await axios.post(
			'https://meetingpoint.2bconnect.pt/api/gvir_operation/create.php',
			{
				equipmentId: gvirData.id,
				thresholdH1: thresholdH1 == undefined ? gvirData.thresholdH1 : thresholdH1,
				thresholdH2: thresholdH2 == undefined ? gvirData.thresholdH2 : final,
				waitingtime: waitingTime == undefined ? waitingTimeRead : waitingTime,
				order_given: sessionStorage.getItem('user'),
			}
		);
		setTimeout(() => {
			fetchGvirStatus();
			fetchGvirOperation();
		}, 3000);
	};


	return (
		<main className='gvir-main'>
			<h3 className='header-text'>GVIR SENSOR STATUS</h3>
			<div className={classes.dataGrid}>
				<DataGrid
					rows={rows}
					columns={columns}
					loading={loading}
					isCellEditable={(params) => params.row.id >= 11}
					onCellEditCommit={handleRowEdition}
					autoHeight

				/><Button
					className={classes.submitButton}
					disabled={!waitingTime}
					onClick={handleSubmission}>
					Salvar WaitingTime
				</Button>
				<Button
					className={classes.submitButton}
					disabled={!thresholdH1 || !thresholdH2}
					onClick={handleSubmission}>
					Salvar H1 / H2
				</Button>
			</div>
		</main>
	);
};

export default Gvir;
