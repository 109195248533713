import React, { useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Context from "../store/context";
import Button from "@material-ui/core/Button";
import { Block } from '@material-ui/icons';
import axios from "axios";


function SubmitAll(props) {

    //Styling Material UI Button Component
    const useStylesButton = makeStyles({
    root: {
        marginTop: "2rem",        
        color: "white",        
        borderRadius: "15px",
        minWidth: "70%",        
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    label: {},
  });

    const classes = useStylesButton();

    const handleClick = () => {
      props.setSubmitAllUpdated(props.SubmitAllUpdated + 1);
      updateAllData();
    }

   //Method to post on Database the orders given to MiddleWare
   const updateAllData = () => {
    console.log("axios");
    axios.post(
      "https://meetingpoint.2bconnect.pt/teste/php/middleware/updateall-mpoperation.php",
      {
        selectedCommands: props.selectedCommands,
        order_given: state.user,
      }
      
    );


  };

    //UseContext to UseGlobal State 
    const { state } = useContext(Context);

  

    return (
        <div className={classes.buttonContainer}>           
            <Button                 
                className={classes.root}
                variant="contained"
                color="primary"
                onClick={() => { 
                  console.log("botao");             
                  handleClick();
            }}
            >          
                Submeter todos            
            </Button>

        </div>
        
        
    )
}

export default SubmitAll;