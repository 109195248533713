//Component Responsabile for reset the System.

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Context from "../store/context";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

function Reset(props) {

  //UseContext to UseGlobal State 
  const { state } = useContext(Context);

  const [role, setRole] = useState(sessionStorage.getItem('role'));
  
  //Hook for setTimeOut
  const [fakeCurrentDate, setFakeCurrentDate] = useState(new Date());

  //Material UI Component 
  const useStylesButton = makeStyles({
    root: {
      background: "linear-gradient(45deg, #4169E1 30%, #483D8B 90%)",
      border: 0,
      borderRadius: 10,
      boxShadow: "0 3px 5px 2px rgba(123, 104, 238)",
      color: "white",
      height: 20,
    },
    label: {},
  });

  const classesButton = useStylesButton();

  //UseEffect to dectec if stateEmergency if false every 3 seconds. 
  //If it's false the value of resetSystem is also false too. 
  //ResetSysyem function only run once. 
  useEffect(() => {
    setTimeout(() => setFakeCurrentDate(new Date()), 3000);
    if (!state.emergencyFlag) {
      state.resetSystem = false;
    }
  }, [fakeCurrentDate]);

  //Function to change Database of Workers.
  //Every workers return to default values. mp = 5 and worker_mp =1 on Database.
  const resetSystem = () => {
    axios
      .post(
        "https://meetingpoint.2bconnect.pt/teste/php/middleware/reset-system.php"
      )
      .catch((err) => {
        console.log(err);
      });
  };

  //Function to hand the click confirmation
  //Change the state of global variable resetSystem to true
  //Execute the function resetSystem
  //props.resetData will force the second render. 
  const handleClick = () => {
    state.resetSystem = true;
    resetSystem();
    props.resetData();
  };

  if (role != 'admin') {
    return (<Button
      className={classesButton.root}
      variant="contained"
      disabled
      onClick={() => {
        if (window.confirm('Quer fazer reset ao sistema?'))
          handleClick();
      }}
    >
      Reset System
    </Button>
    )
  }

  return (
    <Button
      className={classesButton.root}
      variant="contained"
      onClick={() => {
        if (window.confirm('Quer fazer reset ao sistema?'))
          handleClick();
      }}
    >
      Reset System
    </Button>
  );
}

export default Reset;
