//Render the progress bar during a evacuation

import React from "react";
import "../styles/ProgressBar.css";

function ProgressBar(props) {
  //Variable to store the percentage of workers on the safe area.
  const percentage = (props.safe * 100) / props.totalWorkers;

  //Material UI Styling
  const statusSafe = {
    opacity: "1",
    width: `${percentage}%`,
    backgroundColor: "#00e600",
  };

  const statusNotSafe = {
    width: "100%",
    backgroundColor: "#e60000",
    height: "45px",
    border: "5px solid",
    borderRadius: "15px",
  };

  return (
    <div className="progressBar">
      {/*Display the Progress Bar and receive props with data from Database */}
      {/*Message with Info about Progress*/}
      <p className="text-progressbar">
        Trabalhadores nos Pontos de Encontro: {props.safe}
        <br />
        Trabalhadores na Fábricas: {props.notSafe}
      </p>
      {/*Show Progress Bar*/}
      <div class="progress" style={statusNotSafe}>
        <div style={statusSafe}></div>
        {/*Show Percentage of Wokrers registred on Meeting Point*/}
      </div>
      <p className="percentage-progressbar">{parseInt(percentage)}%</p>
    </div>
  );
}

export default ProgressBar;
