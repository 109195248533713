import React from 'react'

//Import Icons from React Icons - https://react-icons.github.io/react-icons/
import * as AiIcons from 'react-icons/ai'
import { FiGrid } from "react-icons/fi";
import { GiWindsock, GiSteamBlast } from "react-icons/gi";
import { RiLogoutCircleRLine, RiAdminLine } from "react-icons/ri";


import '../styles/NavBar.css'

//Options of our SideBar are stored in a Array of Objects 
const TopBarIcons = [    

    {
        title: 'GVIR',
        path: '/gvir',
        icon: <GiSteamBlast />,
        cName: 'nav-text'
    },

    {
        title: 'WIND',
        path: '/wind',
        icon: <GiWindsock />,
        cName: 'nav-text'
    },

    {
        title: 'ADMIN',
        path: '/admin',
        icon: <RiAdminLine />,
        cName: 'nav-text'
    },

    {
        title: 'LOGOUT',
        path: '/login',
        icon: <RiLogoutCircleRLine />,
        cName: 'nav-text'
    },


   
]

export default TopBarIcons;