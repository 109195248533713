import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  buttons: {
    //maxWidth: '70px',
  },
  selectRole: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  warning: {
    color: 'red',
    fontWeight: 'bold',
  }
}));

function EditUserDialog(props) {

  /* useEffect(() => {
      props.fetchUsersData();       
  }, []);

  useEffect(() => {
      console.log("usersData: " + props.usersData[0].name);
      setFullname(props.usersData[0].name);
  }, props.usersData); */

  useEffect(() => {
    getUserDataProps();
  }, [])

  function getUserDataProps() {
    setName(props.name);
    setEmail(props.email);
    //setAlert(props.alert);
    setId(props.id);
  };


  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState("");
  const [id, setId] = useState("");
  const [warning, setWarning] = useState(false);

  const handleClickOpen = () => {
    getUserDataProps();
    setOpen(true);
  };

  const handleClose = () => {
    setWarning(false);
    setOpen(false);
  };

/*   useEffect(() => {
    fetchStates();
  }, []);
 */

  const [h1, setH1] = useState(false);
  const [h2, setH2] = useState(false);
  const [h3, setH3] = useState(false);
  //const [csv, setCsv] = useState(false);
  //const [pdf, setPdf] = useState(false);
  const [format, setFormat] = useState('');



 /*  const fetchStates = async () => {
    axios
      .get(
        "https://meetingpoint.2bconnect.pt/teste/php/middleware/get-state-alerts.php"
      )
      .then((res) => {
        setH1(res.data.alertH1);
        setH2(res.data.alertH2);
        setH3(res.data.alertH3);
      })
      .catch((err) => {
        console.log(err);
      });
  }; */




  const handleChangeH1 = () => {
    h1 == true ? setH1(false) : setH1(true)
  }

  const handleChangeH2 = () => {
    h2 == true ? setH2(false) : setH2(true)
  }

  const handleChangeH3 = () => {
    h3 == true ? setH3(false) : setH3(true)
  }

  const handleChangeFormat = (event) => {
    if(event.target.id == 'csv' &&  format != 'csv'){
      //setCsv(true)
      //setPdf(false)
      setFormat('csv')
    } 
    if(event.target.id == 'pdf' && format != 'pdf'){
      //setPdf(true)
      //setCsv(false)
      setFormat('pdf')
    } 
    if(event.target.id == 'csv' && format == 'csv'){
      //setCsv(false)
      setFormat('')
    } 
    if(event.target.id == 'pdf' && format == 'pdf'){
      //setPdf(false)
      setFormat('')
    } 
  }


  const handleChange = (event) => {

    console.log("handle change: " + event.target.id);
    console.log(event);
    console.log("handle change: " + event.target.value);

    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
    }

  /*   if ((event.target.value == "GVIR Threshold H1") || (event.target.value == "GVIR Threshold H2") || (event.target.value == "GVIR Threshold H3")) {
      setAlert(event.target.value);
    } */
  }

  function validateForm() {

    if ((name == "") || (email == "") || (!h1 && !h2 && !h3)) {
      console.log("Tem de preencher todos os campos!");
      setWarning(true);
      return true;
    }

  }

  function editAlert() {

    console.log("EDIT ALERT");

    if (validateForm()) {
      return;
    }

    axios.post("https://meetingpoint.2bconnect.pt/teste/php/middleware/edit-alert-new.php", {
      format: format,
      name: name,
      email: email,
      alert: alert,
      h1: h1,
      h2: h2,
      h3: h3,
      id: id,
    })
      .then(function (response) {
        props.fetchAlertsData();
      })
      .catch(function (error) {
        console.log(error);
      })

    handleClose();
  }

  const classes = useStyles();

  return (

    <span className={classes.buttonWrapper}>
      <Button size="small" color="primary" variant="contained" className={classes.buttons} onClick={handleClickOpen}>
        Editar
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar</DialogTitle>
        <DialogContent>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            value={name}
            onChange={handleChange}
            fullWidth
            required

          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            value={email}
            onChange={handleChange}
            fullWidth
            required
          />

          <div className={classes.selectRole}>
            <InputLabel id="alert">Tipo de alerta</InputLabel>
            GVIR Threshold
            &nbsp;
            <input
              type="checkbox"
              defaultChecked={false}
              id="H1"
              checked={h1}
              onChange={handleChangeH1}
            />
            &nbsp;
            <label for="H1">H1</label>
            &nbsp;
            <input
              type="checkbox"
              defaultChecked={false}
              id="H2"
              checked={h2}
              onChange={handleChangeH2}
            />
            &nbsp;
            <label for="H2">H2</label>
            &nbsp;
            <input
              type="checkbox"
              defaultChecked={false}
              id="H3"
              checked={h3}
              onChange={handleChangeH3}
            />
            &nbsp;
            <label for="H3">H3</label>
          </div>
          <div className={classes.selectRole}>
            <InputLabel id="alert">Tipo de Relatório</InputLabel>
            Relatório de Emergência
            &nbsp;
            <input
              type="checkbox"
              defaultChecked={format == 'pdf' ? true : false}
              id="pdf"
              //checked={pdf}
              onChange={handleChangeFormat}
            />
             &nbsp;
            <label for="pdf">PDF</label>
            &nbsp;
            <input
              type="checkbox"
              defaultChecked={format == 'csv' ? true : false}
              id="csv"
              //checked={csv}
              onChange={handleChangeFormat}
            />
             &nbsp;
            <label for="csv">CSV</label>
          </div>
          {warning ?
            <div className={classes.warning}>
              Tem de preencher todos os campos antes de editar!
            </div>
            :
            ""
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={editAlert}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  )
}

export default EditUserDialog;