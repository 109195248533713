
import React, { useState, useEffect } from 'react';

/* import 'bootstrap/dist/css/bootstrap.css'; */

import { Pagination } from 'react-bootstrap';
import '../../styles/TablePagination.css';

const TablePagination = ({pageSize, numRows, currentPage, setCurrentPage}) => {
       
    const computePageCount = () => {
        return Math.ceil(numRows / pageSize);
    }
    
    const pagesCount = computePageCount();
        
    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
    };
    
    const handlePreviousClick = () => {
        if (currentPage < 1) {
            return;
        } 
        setCurrentPage(currentPage -1);
    }
    
    const handleNextClick = () => {
        if (currentPage === pagesCount - 1) {
            return;
        }
        setCurrentPage(currentPage +1);
    }

    const createPaginationsLinks = (pages, actualPage) => {
        actualPage++
        const pageRange = 2;

        let fp;
        let lp;
        
        if (pages < pageRange * 2 + 1) {
            fp = 2;
            lp = pages - 1;
        } else if (actualPage < pageRange + 2) {
            fp = 2
            lp = fp + pageRange * 2 
            if (lp >= pages ) lp = pages - 1; 
        } else if (actualPage > pages - (pageRange + 1)) {
            lp = pages - 1
            fp = lp - pageRange * 2 
            if (fp <= 1 ) fp = 2; 
        } else {
            fp = actualPage - pageRange 
            lp = actualPage + pageRange
        } 

        const indexPages = [];

        const hasFirstEllipse = fp > 2
        const hasLastEllipse = lp < pages - 1

        for(let i = fp; i <= lp; i++) {
            indexPages.push(i);
        }
    
        return (
            <>
                <Pagination.Item active={actualPage === 1} onClick={e => handlePageClick(e, 0)}>1</Pagination.Item> 
                {hasFirstEllipse && <Pagination.Ellipsis />}
                {indexPages.map(page => (
                     <Pagination.Item active={page - 1 === currentPage} key={page} onClick={e => handlePageClick(e, page - 1)}>
                         {page}
                     </Pagination.Item>
                ))}
                {hasLastEllipse && <Pagination.Ellipsis />}
                <Pagination.Item active={actualPage === pages} onClick={e => handlePageClick(e, pages - 1)}>{pages}</Pagination.Item>
            </>
        )
        
    }
    
    return (
        
            <Pagination className="pagination-style">
                
                <Pagination.Prev disabled={currentPage <= 0} onClick={handlePreviousClick} />

                {createPaginationsLinks(pagesCount, currentPage)}

                <Pagination.Next disabled={currentPage >= pagesCount} onClick={handleNextClick} /> 
            </Pagination>
        )
   }

export default TablePagination;
