/**
 * Sample to design direction compass using the Circular Gauge
 */
import * as React from "react";
import { registerLicense } from '@syncfusion/ej2-base';
import { CircularGaugeComponent, AxesDirective, AxisDirective, Inject, PointersDirective, PointerDirective, Gradient } from '@syncfusion/ej2-react-circulargauge';

 
// Registering Syncfusion license key.
//registerLicense('MTY1OTA4OUAzMjMxMmUzMTJlMzMzNU1BTzVVZGUrU0prMkhHOWx3eHl5U1pUTzdOMStpSTdIVmFzMHh3Zk80Tm89;Mgo+DSMBaFt+QHFqVkNrWE5Fc0BAXWFKblJ2T2BddVxzZCQ7a15RRnVfRl1rSH5QckdgX3tfeA==;Mgo+DSMBMAY9C3t2VFhhQlJBfVtdXGZWfFN0RnNYf1RzcF9EY0wgOX1dQl9gSXpTfkRjWntcc3JQQ2g=;Mgo+DSMBPh8sVXJ1S0d+X1RPckBDWHxLflF1VWJZdV10fldFcC0sT3RfQF5jTX5bd0VgXX1ddnJdTg==;MTY1OTA5M0AzMjMxMmUzMTJlMzMzNUpXaUlKODlXUXJwOSthVXZUUGhnbUVRSm1YRERiK01YYk9naGFqSHhGQm89;NRAiBiAaIQQuGjN/V0d+XU9Hc1RGQmJMYVF2R2BJfl56d1FMYl9BNQtUQF1hSn5Xdk1iWXxacndSRmRU;ORg4AjUWIQA/Gnt2VFhhQlJBfVtdXGZWfFN0RnNYf1RzcF9EY0wgOX1dQl9gSXpTfkRjWntcc3NUT2g=;MTY1OTA5NkAzMjMxMmUzMTJlMzMzNUd6VEEwL0Qwbzd6d1dyQ3ErVURRYU93SFJseDBSeVE5NGRFbElhSnI2NkU9;MTY1OTA5N0AzMjMxMmUzMTJlMzMzNUF1cENXQlVSdHF0UGlCM0VRU0d4anZLSzFCcGdaeEFvekF5aWV4ZjhtTm89;MTY1OTA5OEAzMjMxMmUzMTJlMzMzNURhc1hxSm83NlFpSXVnQ0VEcDdyMllRemVudUVMMzNVbFlONU9sOExwdnM9;MTY1OTA5OUAzMjMxMmUzMTJlMzMzNVI3bmh0ejJhZ2U2QUVCdFJGbGlvaHRwYUxlSjF4MnRUMU5mbmtIVGtCQmM9;MTY1OTEwMEAzMjMxMmUzMTJlMzMzNU1BTzVVZGUrU0prMkhHOWx3eHl5U1pUTzdOMStpSTdIVmFzMHh3Zk80Tm89');
registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlliSH5adERmWHlaeXM=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpSd0VqW35bcXJTQWc=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0RjUH1fdXVSRmZb;MTY4NjQ3NEAzMjMxMmUzMTJlMzMzNVlCK3d2empKeXpya01BbWw3bFplVDVydFBDYWhKWCt1VE1Jb1luUXNsVUE9;MTY4NjQ3NUAzMjMxMmUzMTJlMzMzNWJsRGFjSDA0VnY0ZGFSWnJKUTIxUWZTU2ZjWDlvaHE0RzRRUVY5UlU3c2M9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckRiWXZccXFXR2FcVQ==;MTY4NjQ3N0AzMjMxMmUzMTJlMzMzNWl0UlN4bWVGckN4S1RpSUxENVZDWmFWbUJ1SDNFZGlQOERaaHNROEVSWXM9;MTY4NjQ3OEAzMjMxMmUzMTJlMzMzNU4vRCtQV2JydnFaVXJkN2o4RFZ0R1JBRmpMczE4eFpQOVFIQW92ZlJCZEE9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0RjUH1fdXZQRmlV;MTY4NjQ4MEAzMjMxMmUzMTJlMzMzNU9mZ2JFOFVGL01XVzZsQlVMZUovYUphcVJrZzV2ZEhCUmxyblJET3VGQmc9;MTY4NjQ4MUAzMjMxMmUzMTJlMzMzNWhydEsveGdSSHNxZWJTRmFvc1RXYUJ6ejBkTmJYb2xLd2hIWG1INk9Fd2c9;MTY4NjQ4MkAzMjMxMmUzMTJlMzMzNWl0UlN4bWVGckN4S1RpSUxENVZDWmFWbUJ1SDNFZGlQOERaaHNROEVSWXM9');

const SAMPLE_CSS = `
    .control-fluid {
		padding: 0px !important;
    }`;
function WindRose(props) {
    let redPointer = (props.rotation * 7.99) / 360;
    let greyPointer;
    if(redPointer >= 4){
        greyPointer = redPointer - 4;
    }else{
        greyPointer = redPointer + 4;
    }
    
    let gauge;
    let pointerColor;
    let labelColor;
    let pointerLinearGradient = {
        startValue: '0%',
        endValue: '100%',
        colorStop: [
            { color: '#ff6b78', offset: '0%', opacity: 0.9 },
            { color: '#e20a22', offset: '70%', opacity: 0.9 }
        ]
    };
    function onLabelRender(args) {
        args.text = ['N', 'NE', 'E', 'SE', 'S', 'SO', 'O', 'NO', ''][args.value];
    }
    ;
    function load(args) {
    }
    return (<div className='control-pane'>
            <style>
                {SAMPLE_CSS}
            </style>
            <div className='control-section'>
                <CircularGaugeComponent load={load.bind(this)} id='direction-gauge' background='transparent' ref={g => gauge = g} axisLabelRender={onLabelRender.bind(this)}>
                    <Inject services={[Gradient]}/>
                    <AxesDirective>
                        <AxisDirective radius='80%' startAngle={0} endAngle={360} minimum={0} maximum={8} majorTicks={{
            height: 15,
            interval: 1
        }} lineStyle={{ width: 20, color: '#E0E0E0' }} minorTicks={{
            height: 10,
            interval: 0.5
        }} labelStyle={{
            font: {
                fontFamily: 'inherit'
            },
            autoAngle: true,
            offset: 10,
            hiddenLabel: 'Last'
        }}>
                            <PointersDirective>
                                <PointerDirective value={redPointer} radius='50%' color='#e20a22' pointerWidth={30} linearGradient={pointerLinearGradient} cap={{
            radius: 15,
            color: '#ffffff',
            border: {
                width: 0
            }
        }} animation={{
            enable: false
        }}/>
                                <PointerDirective value={greyPointer} radius='50%' color='#E0E0E0' pointerWidth={30} cap={{
            radius: 15, color: '#ffffff', border: {
                width: 0
            }
        }} animation={{
            enable: false
        }}/>
                            </PointersDirective>
                        </AxisDirective>
                    </AxesDirective>
                </CircularGaugeComponent>
            </div>
        </div>);
}
  export default WindRose;