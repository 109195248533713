//Render the Information of Each Worker 

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Context from "../store/context";
import "../styles/Home.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

//Material Ui - Button Style
const useStylesButton = makeStyles({
  root: {
    color: "white",
    backgroundColor: "#0593da",
    borderRadius: "15px",
  },
});

//Material Ui - Style Component of Worker Info Card
const useStylesCard = makeStyles({
  root: {
    border: "3px solid black",
    borderRadius: "15px",
    padding: "5px",
    marginBottom: "2vh",
  },
  label: {
    textAlign: "center",
    backgroundColor: "#3b70b1",
    color: "white",
    borderRadius: "15px",
    height: "5vh",
    padding: "10px",
  },
  Radio: {
    marginRight: "1vw",
  },
  status: {
    fontWeight: "1000",
  },
});

function InfoCard(props) {
  //State variable stores Global State
  const { state } = useContext(Context);

  //Material Ui - For more information consult https://material-ui.com/pt/
  const classesButton = useStylesButton();
  const classesCard = useStylesCard();

  //Hook used to store the value of what Meeting Point the user wants to asign the Worker
  const [value, setValue] = useState();

  //Hooks to store data from Fecth
  const [worker, setWorker] = useState([]);

  //Fetch DataBase
  const fectchData = () => {
    axios
      .post(
        "https://meetingpoint.2bconnect.pt/teste/php/Workers/readWorkerById.php",
        {
          input: state.id,
        }
      )
      .then((res) => {
        setWorker(res.data.workers.map((el) => el));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //UseEffect is used for the first fecth when the WebApp is loaded
  useEffect(() => {
    /* setInterval(() => */fectchData()/* , 10000) */;
  }, []);

  //Method to post on Database the current Meeeting Point of each user
  //This method receive as props the Id of user and the Meeting Point
  const updateData = (inputId, inputArea) => {
    axios
      .post(
        "https://meetingpoint.2bconnect.pt/teste/php/middleware/update-mp-workers.php",
        {
          input: inputId,
          mp: inputArea,
          worker_mp: 2,
        }
      )
      .then(({ data }) => {
        if (data.sucess === 1) {
          worker.area = inputArea;
        }
      });
  };

  //Method to update the Meeting Point of each Worker
  const updateDB = () => {
    updateData(state.id, value);
  };

  //Method to change the value of Value's Hook and receive as argument the event target
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="card-info">
      <div class="content-table">
        <tbody>
          <tr>
            {/* Display of the Selected Worker */}
            {worker.map((filteredWorker) => (
              <div>
                <p>Nome: {filteredWorker.worker_name}</p>
                <p>Cargo: {filteredWorker.type}</p>
                <p>Empresa: {filteredWorker.worker_company}</p>

                {/* Display a Message if Emergency is active */}
                {state.emergencyFlag ? (
                  <div>
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      Modo de Emergência está activo
                    </p>
                    {/* Display the Meeting Options*/}
                    <RadioGroup
                      className={classesCard.root}
                      aria-label="status"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_2"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_3"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_4"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio className={classesCard.Radio} />}
                        label="Brigadistas"
                      />
                    </RadioGroup>
                    <Link to="/">
                      <Button
                        className={classesButton.root}
                        variant="contained"
                        onClick={() => {
                          props.close();
                          updateDB();
                        }}
                      >
                        Submeter
                      </Button>
                    </Link>
                    <Button
                      className={classesButton.root}
                      variant="contained"
                      onClick={() => {
                        props.close();
                      }}
                    >
                      Fechar
                    </Button>
                  </div>
                ) : (
                  <div>
                    <RadioGroup
                      className={classesCard.root}
                      aria-label="status"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_1"
                        disabled="true"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_2"
                        disabled="true"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_3"
                        disabled="true"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio className={classesCard.Radio} />}
                        label="MP_4"
                        disabled="true"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio className={classesCard.Radio} />}
                        label="Brigadistas"
                        disabled="true"
                      />
                      <p
                        style={{
                          fontSize: 10,
                          textAlign: "right",
                        }}
                      >
                        *Modo de Emergência não está activo
                      </p>
                    </RadioGroup>
                    <Button
                      className={classesButton.root}
                      variant="contained"
                      onClick={() => {
                        props.close();
                      }}
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </tr>
        </tbody>
      </div>
    </div>
  );
}

export default InfoCard;
