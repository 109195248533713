//This component render how many workers are in each Meeting Point

import React, { useContext, useState } from 'react';
// import Button from "react-bootstrap/Button";
import Button from '@material-ui/core/Button';

import Context from '../store/context';
import '../styles/CardArea.css';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import List from './List';
import { makeStyles } from '@material-ui/styles';

function CardArea(props) {
	const { state } = useContext(Context);
	

	//Method to View the List of Workers for each Zone
	const viewList = (props) => {
		state.listEvent = props.byArea;
	};

	//React Hook used for pop up the list of workers in each meeting point
	const [showPopUp, setShowPopUp] = useState(false);

	//Method to change the state of showPop
	const handleClick = () => {
		setShowPopUp(!showPopUp);
	};

	const useStylesButton = makeStyles({
		root: {
			width: '50%',
			borderRadius: '15px',
			marginBottom: '0.5rem',
		},
	});

	const useStylesText = makeStyles({
		point: {
			fontSize: '1.3rem',
			textAlign: 'center',
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.54)',
		},
	});

	const buttonClasses = useStylesButton();
	const textClasses = useStylesText();

	return (
		<>
			<div>
				{/* Render Component List if showPop is true */}
				{showPopUp ? <List close={handleClick} /> : null}
				<div className='main-card'>
					{/* Render the Detail's Card */}
					<div className={textClasses.point}>
						{/* {`MP  */}{/* $ */}{props.byArea}{/* `} */}
						<br /> {props.name}{' '}
					</div>
					<div className='card-number'>
						<p>
							{props.area.length}
							<PeopleAltIcon />
						</p>
					</div>
					{/* Render the Button to show the Details */}
					<Button
						className={buttonClasses.root}
						variant='contained'
						color='primary'
						onClick={(e) => {
							e.preventDefault();
							viewList(props);
							handleClick();
						}}>
						Detalhes
					</Button>
				</div>
			</div>
		</>
	);
}

export default CardArea;
