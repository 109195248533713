import React, { useState, useEffect, useContext } from 'react';
import Context from '../store/context';
import '../styles/Home.css';
import '../styles/Button.css';

import axios from 'axios';
import '../styles/Search.css';

import SearchList from './SearchList';
import '../styles/CardArea.css';

import ProgressBar from './ProgressBar';

import CardArea from './CardArea';
import '../styles/CardArea.css';

import DisplayMessage from './DisplayMessage';
import SubmitAll from './SubmitAll';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { flexbox } from '@material-ui/system';
import ConfigBox from './ConfigBox.js';
import ConfigBoxSubmitAll from './ConfigBoxSubmitAll';

function Home(/* props */) {
	const { state } = useContext(Context);
	//Hook for Fetching
	const [worker, setWorker] = useState([]);
	const [load, setLoad] = useState(false);
	const [fakeCurrentDate, setFakeCurrentDate] = useState(new Date());
	const [selectedCommands, setSelectedCommands] = useState(selectedCommandsObject);
	//This "1" is a number with no meaning. Refactor needed.
	const [submitAllUpdated, setSubmitAllUpdated] = useState(1);
	const [MP1, setMP1] = useState();
	const [MP2, setMP2] = useState();
	const [MP3, setMP3] = useState();
	const [MP4, setMP4] = useState();
	const [MP5, setMP5] = useState();
	const [MP1ID, setMP1ID] = useState();
	const [MP2ID, setMP2ID] = useState();
	const [MP3ID, setMP3ID] = useState();
	const [MP4ID, setMP4ID] = useState();
	const [MP5ID, setMP5ID] = useState();
	

	//This is just to create the object inside the state. THERE HAS TO BE A BETTER WAY!!
	var selectedCommandsObject = {
		3: '',
		4: '',
		5: '',
		6: '',
		7: '',
		8: '',
	};

	//setTimeout(function(){ console.log(selectedCommands); }, 2000);

	//Fetch Data for the first time
	const fetchWorkers = async () => {
		axios
			.get(
				'https://meetingpoint.2bconnect.pt/teste/php/Workers/readWorkersData.php'
			)
			.then((res) => {
				setWorker(res.data.workers.map((el) => el));
				setLoad(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};


	const fetchMPData = async () => {
		axios
		  .get(
			"https://meetingpoint.2bconnect.pt/teste/php/MP/all-mp.php"
		  )
		  .then((res) => {
			setMP1(res.data.MP[0].name);
			setMP2(res.data.MP[1].name);
			setMP3(res.data.MP[2].name);
			setMP4(res.data.MP[3].name);
			setMP5(res.data.MP[4].name);
			setMP1ID(res.data.MP[0].MP_ID);
			setMP2ID(res.data.MP[1].MP_ID);
			setMP3ID(res.data.MP[2].MP_ID);
			setMP4ID(res.data.MP[3].MP_ID);
			setMP5ID(res.data.MP[4].MP_ID);
			console.log(res.data.MP);
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  }; 

	//UseEffect to Fecth Data for the first time

	useEffect(() => {
		fetchWorkers()
	}, []);

	useEffect(() => {
		setInterval(() => fetchWorkers(), 10000);
	}, []);

	useEffect(() => {
		/* setInterval(() =>  */fetchMPData ()/*, 3000) */;
	}, []); 


	//UseEffect to check if emergenyFlag or resetSystem are activated. Checks every second
	//If emergyFlag or ResetSystem are true. The system fecth data and forces re-render.
	useEffect(() => {
		setTimeout(() => setFakeCurrentDate(new Date()), 1000);
		if (state.emergencyFlag || state.resetSystem) {
			fetchWorkers();
		}

		return () => console.log('cleanup');
	}, [fakeCurrentDate]);
//
	//Variable for filter the data from DB by Worker in each Meeting Point
	const area0 = worker.filter((worker) => worker.mp == 0);
	const area1 = worker.filter((worker) => worker.mp == 1);
	const area2 = worker.filter((worker) => worker.mp == 2);
	const area3 = worker.filter((worker) => worker.mp == 3);
	const area4 = worker.filter((worker) => worker.mp == 4);
	const area5 = worker.filter((worker) => worker.mp == 5);

	//Number of workers registered on Meeting Point
	const totalSafeWorkers =
		area1.length + area2.length + area3.length + area4.length + area5.length;


		console.log(selectedCommands)
	return (
		<div class='content-wrap'>
			{/* Progress Bar and Props to Component*/}
			<div className='progressBarContainer'>
				{load ? (
					<ProgressBar
						safe={totalSafeWorkers}
						totalWorkers={worker.length}
						notSafe={area0.length}
					/>
				) : (
					<h1 className='loading-alert'>Loading...</h1>
				)}
			</div>

			{/* Show search box*/}
			<div className='searchContainer'>
				{/* Search List Component and information from fetchData passed as prop to Component*/}
				<SearchList search={worker} updateData={fetchWorkers} />
			</div>

			<div>
				<div className='header-config'>
					{/* Component to Display Message */}
					<DisplayMessage message={'ZONAS PONTO DE ENCONTRO'} />
				</div>

				{/* Show Cards With Information of How Many Workers are per Meeting Point */}

				<div className='box-details'>
					<CardArea area={area1} byArea={MP1ID} name={MP1} />

					<CardArea area={area2} byArea={MP2ID} name={MP2} />

					<CardArea area={area3} byArea={MP3ID} name={MP3} />

					<CardArea area={area4} byArea={MP4ID} name={MP4} />

					<CardArea area={area5} byArea={MP5ID} name={MP5} />
				</div>
			</div>

			<div className='header-config'>
				{/* Component to Display Message */}
				<DisplayMessage message={'CONFIGURAÇÃO'} />
			</div>

			<Grid container spacing={1}>
				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={MP1ID}
						name={MP1}
						mp={3}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={MP2ID}
						name={MP2}
						mp={4}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={MP3ID}
						name={MP3}
						mp={5}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={MP4ID}
						name={MP4}
						mp={6}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={MP5ID}
						name={MP5}
						mp={7}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBoxSubmitAll
						name={'End Point'}
						mp={8}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>
			</Grid>

			{/* <SubmitAll
				selectedCommands={selectedCommands}
				submitAllUpdated={submitAllUpdated}
				setSubmitAllUpdated={setSubmitAllUpdated}
			/> */}
		</div>
	);
}

export default Home;
