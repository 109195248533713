import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Stage, Layer, Text, Circle, Line, Arrow } from 'react-konva';
import { flexbox } from "@material-ui/system";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import "../styles/WindSensor.css"
import WindRose from "./WindRose"

function WindSensor() {

    const [windDirection, setWindDirection] = useState(0);

    const [windVelocity, setWindVelocity] = useState(0);

    const [lastUpdate, setLastUpdate] = useState();

    const [status, setStatus] = useState();

    const [tempo1, setTempo1] = useState("5min");

    const [tempo2, setTempo2] = useState("10min");


    useEffect(() => {
        setInterval(() => {
            fetchWindData();
        }, 10000);
    }, []);


    useEffect(() => { fetchMpStatus(); });

    useEffect(() => { fetchWindData(); });

    useEffect(() => {
        console.log("windDirection's new value: " + windDirection);
    }, [windDirection])


    const fetchWindData = () => {
        axios
            .post(
                "https://meetingpoint.2bconnect.pt/teste/php/middleware/read-wind-data.php"
            )
            .then((res) => {
                console.log(res);
                setWindDirection(res.data.windData[0].direction);
                setWindVelocity(res.data.windData[0].velocity);
                setLastUpdate(res.data.windData[0].datatime)

            })
            .catch((err) => {
                console.log(err);
            })

    }

    const fetchMpStatus = () => {
        axios
            .post(
                "https://meetingpoint.2bconnect.pt/teste/php/middleware/read-mpstatus-status.php"
            )
            .then((res) => {
                console.log(res);
                setStatus(res.data.status[0].hw_status);

            })
            .catch((err) => {
                console.log(err);
            })

    }

    const useStyles = makeStyles({
        table: {
            maxWidth: "500px",
        },
        head: {
            backgroundColor: "black",
            color: "white",
        },
        tableWrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
    });

    function createData(name, value) {
        return { name, value };
    }

    let windDir = Number(windDirection);
    windDir > 179 ? windDir = windDir - 180 : windDir = windDir + 180;
    let degrees = windDir >= 0 && windDir < 11.25 ? 'º N' : windDir > 11.24 && windDir < 33.75 ? 'º NNE' : windDir > 33.74 && windDir < 56.25 ? 'º NE' : windDir > 56.24 && windDir < 78.75 ? 'º ENE' : windDir > 78.74 && windDir < 101.25 ? 'º E' : windDir > 101.24 && windDir < 123.75 ? 'º ESSE' : windDir > 123.74 && windDir < 146.25 ? 'º SE' : windDir > 146.24 && windDir < 168.75 ? 'º SSE' : windDir > 168.74 && windDir < 191.25 ? 'º S' : windDir > 191.24 && windDir < 213.75 ? 'º SSO' : windDir > 213.74 && windDir < 236.25 ? 'º SO' : windDir > 236.24 && windDir < 258.75 ? 'º OSO' : windDir > 258.74 && windDir < 281.25 ? 'º O' : windDir > 281.24 && windDir < 303.75 ? 'º ONO' : windDir > 303.74 && windDir < 326.25 ? 'º NO' : windDir > 326.24 && windDir < 348.75 ? 'º NNO' : windDir > 348.74 && windDir <= 360 ? 'º N' : null

    const options1 = ["5min","10min","15min","20min"];

    const options2 = [
        {
            value: 1,
            label: "10min"
        },
        {
            value: 2,
            label: "15min"
        },
        {
            value: 3,
            label: "20min"
        },
        {
            value: 4,
            label: "25min"
        }
    ];

    const handleChange1 = (e) => {
        console.log(e.target.value);
        setTempo1(e.target.value);
    };

    const handleChange2 = (e) => {
        console.log(e.target.value);
        setTempo2(e.target.value);
    };


    const rows = [
        
        createData('Tempo',    <div>
        {/* <InputLabel id="roleId">Tipo</InputLabel> */}
        <Select
          labelId="tempo1"
          id="minutos1"
          value={tempo1}
          onChange={handleChange1}
        >
          <MenuItem value={"5min"}>5min</MenuItem>
          <MenuItem value={"10min"}>10min</MenuItem>
          <MenuItem value={"15min"}>15min</MenuItem>
          <MenuItem value={"20min"}>20min</MenuItem>
        </Select>
        <span> / </span> 
        <Select
          labelId="tempo2"
          id="minutos2"
          value={tempo2}
          onChange={handleChange2}
        >
          <MenuItem value={"10min"}>10min</MenuItem>
          <MenuItem value={"15min"}>15min</MenuItem>
          <MenuItem value={"20min"}>20min</MenuItem>
          <MenuItem value={"25min"}>25min</MenuItem>
        </Select>
      </div>),
       
        createData('Direção', windDir + degrees),
        /* createData('Velocidade (km/h)', windVelocity), */
        createData('Intensidade (m/min)', (windVelocity * 16.667).toFixed(2)),
        createData('Última actualização', lastUpdate),
        createData('Status', status),
    ];

    const classes = useStyles();


    return (
        <div className="back" >
            <main className="content-wrap-wind">

                <Stage width={window.innerWidth} height={window.innerHeight / 1}>
                    <Layer >



                        {/*  <Circle x={window.innerWidth / 2.8} y={window.innerHeight / 1.18} radius={100} stroke={"black"} /> */}
                        {/* <Circle x={window.innerWidth / 2} y={window.innerHeight / 3} radius={125} stroke={"black"} />
                    <Circle x={window.innerWidth / 2} y={window.innerHeight / 3} radius={100} stroke={"black"} />
                    <Circle x={window.innerWidth / 2} y={window.innerHeight / 3} radius={75} stroke={"black"} />
                    <Circle x={window.innerWidth / 2} y={window.innerHeight / 3} radius={50} stroke={"black"} />
                    <Circle x={window.innerWidth / 2} y={window.innerHeight / 3} radius={25} stroke={"black"} /> 
                    <Circle x={window.innerWidth / 2} y={window.innerHeight / 3} radius={10} stroke={"red"} fill={"red"} />  */}

                        {/*    <Arrow className={"arrow"}
                            x={window.innerWidth / 2.8}
                            Y={window.innerHeight / 1.18}
                            stroke="red"
                            strokeWidth={5}
                            points={[0, 0, 0, -95]}
                            rotation={windDirection}
                        />  */}

                        {/*                         <Arrow className={"arrow"}
                            x={window.innerWidth / 2.34}
                            Y={window.innerHeight / 1.65}
                            stroke="red"
                            strokeWidth={5}
                            points={[0, 0, 0, -145]}
                            rotation={windDirection}
                        /> */}

                        <Arrow className={"arrow"}
                            x={window.innerWidth / 2.10}
                            Y={window.innerHeight / 1.55}
                            stroke="red"
                            strokeWidth={5}
                            pointerWidth={80}
                            pointerLength={0}
                            points={[0, 0, 40, -250]}
                            rotation={windDir}
                        />

                        <Arrow className={"arrow"}
                            x={window.innerWidth / 2.10}
                            Y={window.innerHeight / 1.55}
                            stroke="red"
                            strokeWidth={5}
                            pointerWidth={80}
                            pointerLength={0}
                            lineJoin="round"
                            points={[0, 0, -40, -250]}
                            rotation={windDir}
                        />

                        <Arrow className={"arrow"}
                            x={window.innerWidth / 2.10}
                            Y={window.innerHeight / 1.55}
                            stroke="red"
                            strokeWidth={5}
                            pointerWidth={40}
                            pointerLength={0}
                            points={[0, 0, 20, -125]}
                            rotation={windDir}
                        />

                        <Arrow className={"arrow"}
                            x={window.innerWidth / 2.10}
                            Y={window.innerHeight / 1.55}
                            stroke="red"
                            strokeWidth={5}
                            pointerWidth={40}
                            pointerLength={0}
                            lineJoin="round"
                            points={[0, 0, -20, -125]}
                            rotation={windDir}
                        />
                        {/* <Arrow className={"arrow"}
                            x={window.innerWidth / 2}
                            Y={window.innerHeight / 3}
                            pointerAtBeginning={true}
                            pointerAtEnd={true}
                         pointerLength={50}
                            stroke="red"
                            strokeWidth={5}
                            height={10}
                            points={[0, 0, 0, -150, 0, 0]}
                            offset={[100,50]} 
                            points={[0, 0, 0, -290,-50,-20,-20]} 
                            rotation={windDirection}
                        /> */}

                        {/*                     <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={45}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={90}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={135}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={180}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={225}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={270}
                    />

                    <Line stroke="black"
                        strokeWidth={0.5}
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        points={[0, 0, 0, -150]}
                        rotation={315}
                    />
 */}
                        {/* 
                        <Text text="N"
                            x={window.innerWidth / 2.8}
                            Y={window.innerHeight / 1.1}
                        
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                        />

                        <Text text="NE"
                            x={window.innerWidth / 2.99}
                            Y={window.innerHeight / 1.12}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={45}
                        />

                        <Text text="E"
                            x={window.innerWidth / 3.09}
                            Y={window.innerHeight / 1.18}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={90}
                        />

                        <Text text="SE"
                            x={window.innerWidth / 2.95}
                            Y={window.innerHeight / 1.26}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={135}
                        />

                        <Text text="S"
                            x={window.innerWidth / 2.8}
                            Y={window.innerHeight / 1.29}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={180}
                        />

                        <Text text="SO"
                            x={window.innerWidth / 2.64}
                            Y={window.innerHeight / 1.25}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={225}
                        />

                        <Text text="O"
                            x={window.innerWidth / 2.56}
                            Y={window.innerHeight / 1.18}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={270}
                        />

                        <Text text="NO"
                            x={window.innerWidth / 2.67}
                            Y={window.innerHeight / 1.11}
                            offsetY={180}
                            offsetX={5}
                            fontStyle="bold"
                            fontSize={15}
                            rotation={315}
                        /> */}

                    </Layer>

                </Stage>

                <div style={{ marginLeft: '70%', marginTop: '-38%' }} className={classes.tableWrapper} >
                    <TableContainer className={classes.table} component={Paper}>
                        <WindRose rotation={windDir} />
                        <Table size="small" aria-label="simple table">
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow /* className="table-row"  */ key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.value}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>

                </div>




            </main>
        </div>
    );
}

export default WindSensor;